<template>
  <section>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'principes' })" />
        </div>
      </div>

      <div class="shdow">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <p class="h2 text-secondary">Modifier un principe</p>
          <b-button variant="secondary">Confirmer</b-button>
          <hr class="w-100" />
        </div>

        <!-- inputs -->
        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group label-cols="12" label="Libellé" label-class="font-weight-bold">
              <b-form-input type="text" value="Test"></b-form-input>
            </b-form-group>
            <b-form-group label-cols="12" label="Code" label-class="font-weight-bold">
              <b-form-input type="text" value="Code-50"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Date creation"
              label-class="font-weight-bold"
              value="12-20-2020"
            >
              <b-datepicker value="12-12-2020"></b-datepicker>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="12"
              label="Description"
              label-class="font-weight-bold"
            >
              <b-form-textarea
                rows="5"
                value="lorem impsum loremp impsum lllslslslslslskskjsjsjs"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <!-- end inputs -->
      </div>

      <!-- Tabs -->
      <b-tabs class="tab-solid tab-solid-primary mt-5">
        <b-tab title="Cadres rattachés">
          <div class="d-flex justify-content-end pr-4 mb-3">
            <b-button class="align-btn-table" @click="showAddCadre = true">
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter un cadre
            </b-button>
          </div>
          <Cadres :fields="cadre.fields" :items="cadre.items" />
        </b-tab>
        <b-tab title="Implémentation">
          <div class="d-flex justify-content-end pr-4 mb-3">
            <b-button class="align-btn-table" @click="showAddObject = true">
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter un objet
            </b-button>
          </div>
          <Implementation :fields="impl.fields" :items="impl.items" />
        </b-tab>
        <b-tab title="Couverture project">
          <div class="d-flex justify-content-end pr-4 mb-3">
            <b-button class="align-btn-table" @click="showAddObject = true">
              <i class="mdi mdi-plus mr-2"></i>
              Ajouter un objet
            </b-button>
          </div>
          <Implementation :fields="impl.fields" :items="impl.items" />
        </b-tab>
      </b-tabs>

      <!-- End Tabs -->
    </b-card>

    <!-- Add Cadre Modal -->
    <div to="modals-xyz-548" v-if="showAddCadre">
      <modal @close="showAddCadre = !showAddCadre">
        <add-cadre />
      </modal>
    </div>

    <!-- Add Object Modal -->
    <div to="modals-xyz-548" v-if="showAddObject">
      <modal @close="showAddObject = !showAddObject">
        <add-object />
      </modal>
    </div>
  </section>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import Cadres from "./components/Cadres.vue";
import Implementation from "./components/Implementations.vue";
import Modal from "@/components/custom/Modal.vue";
import AddCadre from "./components/AddCadre.vue";
import AddObject from "./components/AddObject.vue";
export default {
  components: { ModalActions, Cadres, Implementation, Modal, AddCadre, AddObject },
  data: () => ({
    showAddCadre: false,
    showAddObject: false,
    cadre: {
      items: [
        {
          type: "Regles",
          code: 56,
          libelle: "Api station",
          couverture: 30,
          status: "Production",
          capacite: "Services d'echanges",
          implementaion: 4,
          livrable: 2,
          // actions: ["edit", "delete", "show"],
          actions: ["show"],
        },
      ],
      fields: [
        { key: "type", label: "Type cadre", sortable: true },
        { key: "code", label: "Code", sortable: true },
        { key: "libelle", label: "Libellé", sortable: true },
        { key: "couverture", label: "Couverture projet", sortable: true },
        { key: "capacite", label: "Capacite technique", sortable: true },
        { key: "implementaion", label: "Implémentaion", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "livrable", label: "Livrables", sortable: true },
        { key: "actions", label: "" },
      ],
    },
    impl: {
      items: [
        {
          type: "Composant Technique",
          libelle: "OVH",
          po: "O. GUISSE",
          capacite: "Cloud",
          patrimoine: 4,
          // actions: ["edit", "delete", "show"],
          actions: ["delete"],
        },
      ],
      fields: [
        { key: "type", label: "Type objet", sortable: true },
        { key: "libelle", label: "Libellé objet", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "capacite", label: "Capacité", sortable: true },
        { key: "patrimoine", label: "Patrimoine", sortable: true },
        { key: "actions", label: "" },
      ],
    },
  }),
};
</script>
